.contact-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-icons-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-self: center;
}

.contact-button {

  padding: 20px;
  border-radius: 1.5rem;
  box-shadow: 8px 10px 20px 1px rgba(0,0,10,.2);
  transition: .2s all;

}

.contact-button:hover {
  transform: translateY(-3px)
}

.contact-button:active {
  transform: translate(3px, 2px);
  box-shadow: 3px 5px 8px 1px rgba(0,0,10,.2);
}