
.info-iframe-container {
  min-width: 100%;
}


.info-iframe {
  width: 100%;
  height: 100%;
  padding: 0;
  border-radius: 0;
  overflow: hidden;
}

.info-iframe-hide-top {
  width: 100%;
  height: 150%;
  margin-bottom: 0px;
  margin-top: -60px;
  padding: 0;
  border-radius: 0;
  overflow: hidden;
}