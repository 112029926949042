.footer {
  display: flex;
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 70px;
  width: 100%;
  background-color: var(--ytf-green);
  overflow-y: hidden;
  justify-content: center;
  color: white;
}