.current-category-title {
  display: absolute;
  top: 0;
  color: white;
  position: fixed;
  font-size: 2.4rem;
  font-weight: bold;
  padding: 1rem 2rem;
  font-family: var(--main-font);
  z-index: 1100;

}

.custom-accordion {
    width: 100%;
}

.accordion-item .custom-accordion-item {
    background-color: rgb(51, 29, 8);
    font-size: 2rem;
    font-weight: normal;
}

.accordion-button {
    font-size: 2.4rem !important;
    font-weight: bold;
    flex-grow: 1;
    padding: 1rem 2rem;
}

.accordion-button:focus {
    box-shadow: none !important;
    border-color: black !important;
    background-color: darkseagreen;
}
.accordion-button:active {
    box-shadow: none !important;
    background-color: darkseagreen;
    border-color: black !important;
}

.accordion-button:not(.collapsed) {
    background-color: darkseagreen !important;
    color: black !important;
}
