.home-buttons-container {
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  min-width: 95%;
  flex-grow: 1;
}

.home-buttons-row {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}


