.custom-navbar {
  display: flex;
  position: sticky;
  top: 0;
  justify-content: center !important;
  height: 60px;
  width: 100%;
  max-width: 100%;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 200;
  /* background-color: var(--ytf-red); */
  background-color: var(--ytf-green);
  /* background-color: black; */
  padding: 20px;
  font-family: "Shrikhand";
  z-index: 200;
  color: white;
  box-shadow: 0.1rem 0.2rem 0.3rem 0.1rem rgba(0, 0, 0, 0.3);
}


.nav-logo {
    display: none;
}

.navbar-nav {
    justify-content: space-between;
    width: 100%;
}

.nav-links {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
}
.nav-links a {
    text-decoration: none;
    color: white;
}

.non-icon {
    display: none;
    color: white;
    font-family: var(--main-font);
    font-size: 1.5rem;
}

.icon {
    font-size: 40px;
}

/* Desktop view shows family name in navbar and full link words */
@media (min-width: 900px) {
    .custom-navbar {
        justify-content: space-between !important;
    }

    .navbar-expand {
        flex-wrap: wrap;
    }

    .non-icon {
        display: flex;
    }
    .nav-logo {
        display: inline;
        align-items: center;
        font-size: 1.6rem;
        padding: 0 10px;
    }
    .navbar-nav {
        justify-content: flex-end;
        max-width: 50%;
    }
    .icon {
        font-size: 25px;
    }
}
