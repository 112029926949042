.customer-info-container {
    display: block;
    position: relative;
    text-align: start;
    font-family: var(--main-font);
    font-size: 2rem;
}

.delete-customer-icon {
  position: absolute;
  right: 1rem;
  top: .5rem;
}


.custom-plants-header #plants-header {
    font-size: 1rem;
}

.customCheckbox input:checked {
  color: black;
  background-color: green;
}

.customCheckbox {
  display: inline-block;
  text-align: start;
  font-size: 2rem;
  padding: 1rem 1rem 1rem 3rem;

}

.customCheckbox .form-check-label {
  font-size: 2rem;
  line-height: 2rem;
}

.btn-note {
  border-radius: 8px;
  border: none;
  height: 4rem;
  font-size: 2rem;
  color: white;
  background-color: green;
  margin: auto;
  margin-left: 1rem;
  /* width: 100%; */
}

.primary-note {
  padding: 0 1rem;
  font-size: 1.75rem;
  width: 100%;
  height: 8rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.note-area {
  padding: 0 1rem;
  font-size: 1.75rem;
  width: 100%;
  height: 8rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.note-area:focus {
  outline: var(--ytf-green) auto 3px;
}

.note{
  font-size: 1.75rem;
  padding: 0 1rem 1rem 1rem;
  width: 100%;
  height: fit-content;
  border-bottom: 1px solid lightgray;
  margin-top: 2px;
  /* border-style: dashed; */
  /* border-radius: 8px; */
  line-height: normal;
  font-weight: normal;
}



.note:focus-within,
.note:focus-within .editable-note {
  outline: none;
  transition-property: font-size, background-color, height;
  transition-duration: .5s;
  height: fit-content;
  font-weight: bold;
  background-color: whitesmoke;
  font-size: 2rem;
}

.note-highlighted {
  outline: none;
  transition-property: all;
  transition-duration: .5s;
  height: fit-content;
  font-weight: bold;
  background-color: whitesmoke;
  font-size: 2rem;

  /* other styles you want to apply when highlighted */
}

.editable-note {
  outline: none;
  border: none;
  /* height: fit-content; */
  height: 100%;
  overflow: show;
  width: 100%;
  /* font-size: inherit; */
}

.editable-note:focus {
  outline: none;
  transition-property: all;
  transition-duration: .5s;
  height: fit-content;
  font-weight: bold;
  background-color: whitesmoke;
  font-size: 2rem;
}