.main-container {
    padding: 60px 0px 70px 0px;
    max-width: 1600px;
    height: 100%;
    margin: auto;
}

.customCheckbox input:checked {
  color: black;
  background-color: green;
}


.customCheckbox {
  display: inline-block;
  text-align: start;
  font-size: 2rem;
  padding: 1rem 1rem .5rem 3rem;

}

.customCheckbox .form-check-label {
  font-size: 1.75rem;
  line-height: 2rem;
}


.photo-icon {
  font-size: 1.8rem;
}