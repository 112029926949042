.home-page-link {
  text-decoration: none;
  max-height: 100%;
  height: auto;
  max-width: 50%;
  /* width: calc(7.5rem + 5vh); */
  /* height: calc(7.5rem + 5vh); */
  /* margin: 10px; */
  /* max-width: 200px; */
}

.home-page-button {
  background: linear-gradient(90deg, var(--ytf-green), green);
  border: none;
  border-radius: 1rem;
  font-size: 1.8rem;
  color: white;
  width: 100%;
  margin: 10px;
  min-width: 130px;
  text-decoration: none;
  font-family: var(--main-font);
  box-shadow: 0.3rem 0.4rem 0.3rem 0.1rem rgba(0, 0, 10, 0.2);
}

.home-page-button:active {
  transform: translateY(2px) translateX(1px);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 10, 0.2);
  transition: all 0.1s;
}

@media screen and (min-height: 700px) {
  .home-page-button {
    font-size: 2.5rem;
  }
}

@media screen and (min-width: 767px) {
  .home-page-button {
    font-size: 3rem;
    font-weight: 800;
  }
}
