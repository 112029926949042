.InfoModalImage {
    display: flex;
    margin: auto;
    min-height: 100px;
    max-height: 300px;
    max-width: 300px;
}

/* .InfoModalImage .img-fluid {
  margin: auto;
  transform: translateX(100%);
} */

div .modal-title {
    font-size: 2rem;
}

div .modal-footer {
  border-top: none
}

div .modal-content {
    border-radius: 2rem;
    max-width: 90%;
    margin: auto;
}

div button .btn-sm {
    font-size: 1.6rem;
}

.modal-content {
    overflow: hidden;
}
