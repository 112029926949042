.home-container {
    height: 100%;
    display: flex;
}

.home-user-title {
    color: var(--ytf-green);
    font-size: 3rem;
}

.home-page-button {
    background: linear-gradient(90deg, var(--ytf-green), green);
    border: none;
    border-radius: 1rem;
    font-size: 3rem;
    color: white;
    width: 100%;
    min-width: 150px;
    text-decoration: none;
    font-family: var(--main-font);
    box-shadow: 0.4rem 0.4rem 0.2rem 0.1rem rgba(0, 0, 0, 0.2);
}

.home-page-button:active {
    transform: translateY(2px) translateX(1px);
    box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
    transition: all 0.1s;
}

.home-spacer {
    height: 1.5rem;
    flex-grow: 1;
    justify-content: end;
    align-items: center;
}

#main-logo {
    max-width: 100%;
    max-height: 100%;
}
.main-logo-container {
    width: 35vh;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 767px) {
  .home-user-title {
    color: var(--ytf-green);
    font-size: 4rem;
}
}