.react-router-link {
    text-decoration: none;
    color: black;
}

.btn-add {
    border-radius: 10px;
    border: none;
    height: 4rem;
    font-size: 2rem;
    color: white;
    background-color: green;
    margin: 1rem auto;
    width: 100%;
}

a .btn-add {
    margin: 1rem auto;
    width: 100%;
}

div .form-select,
.custom-select {
    font-size: 1.6rem;
}

.plant-aside {
    font-size: 1.4rem;
    color: gray;
}
