.employee-info-container {
  display: block;
  text-align: start;
  font-family: var(--main-font);
  font-size: 2rem;
}

.custom-plants-header #plants-header {
  font-size: 1rem;
}
