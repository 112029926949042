.sign-in-background {
    background-color: rgb(10, 66, 0);
}

.sign-in {
    max-height: 80rem;
    max-width: 40rem;
    width: 30rem;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0.1rem 0.1rem 0.3rem 0rem rgba(0, 0, 0, 0.2);
    color: black;
    padding: 5px;
    font-size: 1.6rem;
    padding-bottom: 1.5rem;
}

label {
    font-size: 1.6rem;
}

.sign-in-button {
    border-radius: 0.5rem;
    margin-top: 1rem;
    /* padding: 5px; */
    background-color: rgb(10, 66, 0);
    color: white;
    font-size: 2rem;
}

.sign-in-button:hover,
.sign-in-button:focus {
    background-color: green;
}

.form-control {
    font-size: 1.6rem !important;
}

.login-links {
  display: flex;
  justify-content: space-between;
  width: 30rem;
  max-width: 40rem;

}

.password-reset-instructions {
  padding: 1rem 1rem;
  background-color: lightyellow;
  margin: 1rem 0;
  line-height: 1.5;
  width: 30rem;
}

.sign-in-footer { 
  position: relative;
  width: 30rem;
  height:max-content;
}

.back-arrow-footer {
  position: absolute;
  top: 0;
  left: 0;
}

.login-footer {
  /* position: absolute; */
  margin: 2.2rem auto;
}