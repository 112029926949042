.initial-title {
  font-size: 2.5rem;
}

.initial-container {
  display: block;
  text-align: start;
  max-width: 700px;

}


.instructions {
  padding: 10px;
  display: block;

}

.instructions ol {
  text-align: start;
  font-size: large;
  font-weight: 600;
}

.instructions li {
  padding: 5px;
}

#select {
  max-width: 500px;
}

.ytf-icon img {
  width: 55px;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,.2);
  border-radius: 5px;
}



.front-layer-chores-tutorial {
  position: fixed;
  bottom: 70px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,.2);
  width: 90%;
  max-width: 600px;
  z-index: 0;
}

.btn-add {
  border-radius: 10px;
  border: none;
  height: 4rem;
  font-size: 2rem;
  color: white;
  background-color: green;
  margin: 1rem auto;
  width: 100%;
  text-decoration: none;
  text-decoration-line: none;
}

a .btn-add {
  margin: 1rem auto;
  width: 100%;
  text-decoration: none !important
}
/* Ipad / tablet views */
@media (max-width: 912px) and (min-height: 1024px){


}