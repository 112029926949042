@import url("https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap");
/* font-family: 'Gochi Hand', cursive; */
@import url("https://fonts.googleapis.com/css2?family=Gochi+Hand&family=Shrikhand&display=swap");
/* font-family: 'Shrikhand', cursive; */
@font-face {
    font-family: "UberhandPro";
    src: url("./assets/fonts/UberhandTextProRegular.TTF");
    font-weight: normal;
}
@font-face {
    font-family: "UberhandPro";
    src: url("./assets/fonts/UberhandTextProBold.TTF");
    font-weight: bold;
}

:root {
    --main-font: "UberhandPro";
    --ytf-green: rgb(10, 66, 0);
    --ytf-red: rgb(205, 0, 7);
    font-size: 62.5%;
}

html,
body {
    font-size: 1.6rem;
    font-family: var(--main-font);
}

.h1,
h1 {
    font-family: "UberhandPro";
    font-weight: 600 !important;
    font-size: 2.5rem;
}

.h2,
h2 {
    font-family: "UberhandPro";
    font-weight: 600 !important;
    font-size: 2rem;
}

.App {
    display: flex;
    justify-content: center;
    text-align: center;
    font-style: "UberhandPro";
    min-height: 100vh;
    font-size: 1.6rem;
}

.small-font {
    font-size: 1.6rem;
}

.medium-font {
    font-size: 2rem;
}

.large-font {
    font-size: 2.5rem;
}

.extra-large-font {
    font-size: 4rem;
}
